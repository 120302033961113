import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import '../styles/Moments.css';
import img1 from '../images/happy memories.jpg';
import img2 from '../images/meetings.jpg';
import { Carousel } from 'react-responsive-carousel';

const Moments = () => {
  return (
    <Container
      id="moments"
      className="container"
      width={'100%'}
      bgColor={'whitesmoke'}
      h="90vh"
      padding={'10'}
    >
      <Heading display={'flex'} justifyContent={'center'} alignItems={'center'}>
        Events
      </Heading>
      <div className="moments__subheading">
        <h4>Share your Moments</h4>
        <p>In our Restraunt</p>
      </div>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        className={'moments__carousel'}
        showArrows={true}
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
      >
        <div>
          <img src={img1} alt="We host your happy memories" />
        </div>
        <div>
          <img src={img2} alt="A perfect place for your meetings" />
        </div>
      </Carousel>
    </Container>
  );
};

export default Moments;
