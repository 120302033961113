import React from 'react';
import '../styles/MenuDetails.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import fileUrl from '../assets/Menu.pdf';
import { Helmet } from 'react-helmet-async';

const MenuDetails = () => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Check our wide variety of indian dishes at very low prices"
        />
        <link rel="canonical" href="/menudetails" />
      </Helmet>
      <section className="menudetails__container">
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '850px',
          }}
          className="pdf__container"
        >
          <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
            <Viewer fileUrl={fileUrl} />
          </Worker>
        </div>
      </section>
    </>
  );
};

export default MenuDetails;
