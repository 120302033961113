import React, { useState } from 'react';
import image from '../images/reservation.jpg';
import '../styles/BookTable.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';

const BookTable = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [people, setPeople] = useState('');
  const [message, setMessage] = useState('');

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const submitHandler = async e => {
    e.preventDefault();
    const myForm = new FormData();

    myForm.set('name', name);
    myForm.set('email', email);
    myForm.set('phone', phone);
    myForm.set('date', date);
    myForm.set('time', time);
    myForm.set('people', people);
    myForm.set('message', message);

    const { data } = await axios.post(
      `https://ten-spoon-backend.onrender.com/api/v1/booktable/email`,
      myForm,
      config
    );

    if (data.info.messageId) {
      toast.success('Email Send Successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }

    // Clearing Form Data
    ResetForm();
  };

  const ResetForm = () => {
    console.log('Clearing form Data');
    setName('');
    setEmail('');
    setPhone('');
    setDate('');
    setTime('');
    setPeople('');
    setMessage('');
  };

  return (
    <>
      <Helmet>
        <meta
          title="Book your table"
          name="description"
          content="Book a table with us and feel the pleasure of luxury at affordable price"
        />
        <link rel="canonical" href="/booktable" />
      </Helmet>
      <div className="booktablecontainer">
        <div className="booktableimage">
          <img src={image} alt="Book a Table with Ten Spoon" />
        </div>
        <form
          onSubmit={submitHandler}
          className="detailField"
          encType="multipart/form-data"
        >
          <div className="details">
            <input
              required
              type="text"
              value={name}
              placeholder=" Your Name"
              onChange={e => setName(e.target.value)}
            />
            <input
              required
              type="email"
              value={email}
              placeholder="Your Email"
              onChange={e => setEmail(e.target.value)}
            />
            <input
              required
              type="number"
              value={phone}
              placeholder="Your Phone"
              onChange={e => setPhone(e.target.value)}
            />
            <input
              required
              type="date"
              value={date}
              placeholder="Date"
              onChange={e => setDate(e.target.value)}
            />
            <input
              required
              type="text"
              value={time}
              placeholder="Time"
              onChange={e => setTime(e.target.value)}
            />
            <input
              required
              type="number"
              value={people}
              placeholder="# of people"
              onChange={e => setPeople(e.target.value)}
            />
          </div>
          <input
            placeholder="Message"
            type="text"
            className="message"
            value={message}
            rows="5"
            onChange={e => setMessage(e.target.value)}
          ></input>
          <input type="submit" name="Submit" className="submitButton" />
        </form>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default BookTable;
