import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import about from '../images/About.jpg';
import '../styles/About.css';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

const About = () => {
  const [text] = useTypewriter({
    words: ['Patti Kalyana', 'Samalkha', 'Panipat'],
    loop: {},
    typeSpeed: 120,
  });
  return (
    <Container id="about" className="about__container">
      <Heading
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingTop={'20'}
      >
        About
      </Heading>
      <div className="about__subheading">
        <p>Learn More</p>
        <span>About Us</span>
      </div>
      <div className="horizontal__grid">
        <img src={about} alt="About Ten Spoon" />
        <div>
          <div>
            <h3 className="typewriter" id="typewriter">
              Ten Spoon Welcomes You!
            </h3>
            <h3 className="typewriter">
              Best restaurant in{'  '}
              <span style={{ fontWeight: 'bold', color: 'rgb(148, 82, 82)' }}>
                {text}
              </span>
              <span style={{ color: 'rgb(148, 82, 82)' }}>
                <Cursor />
              </span>
            </h3>
          </div>
          <div className="about__content">
            <ul>
              <li>
                Ten Spoon was established in 2019.
                <br />
                Surender Malik is managing director of this dhaba.
              </li>
              <li>
                We always commit to serve best quality and delicious food at
                reasonable price, also commit to provide a hygiene environment
                and friendly nature staff for your convenience.
              </li>
            </ul>
          </div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/o5F_zTYAF8s"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullscreen
          ></iframe>
        </div>
      </div>
    </Container>
  );
};

export default About;
