import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../images/view1.jpg';
import img2 from '../images/view2.jpg';
import img4 from '../images/galley1.png';
import img5 from '../images/galley2.png';
import img7 from '../images/gallery4.jpg';
import img8 from '../images/gallery5.jpg';
import img10 from '../images/img10.jpg';
import '../styles/Gallery.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Navigation, Pagination } from 'swiper';

const Gallery = () => {
  return (
    <Container className="gallery__container" pt={'50'} id="gallery">
      <Heading display={'flex'} alignItems={'center'} justifyContent={'center'}>
        Gallery
      </Heading>
      <div className="gallery__subheading">
        <h4>Check</h4>
        <p>Our Gallery</p>
      </div>
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        pagination={{
          clickable: true,
        }}
        navigation={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide className="swiper">
          <img src={img1} alt="Table and Chair" />
        </SwiperSlide>
        <SwiperSlide className="swiper">
          <img src={img2} alt="Dining Hall" />
        </SwiperSlide>
        <SwiperSlide className="swiper">
          <img src={img4} alt="Fountain" />
        </SwiperSlide>
        <SwiperSlide className="swiper">
          <img src={img5} alt="Multiple Tables" />
        </SwiperSlide>
        <SwiperSlide className="swiper">
          <img src={img7} alt="Ten Spoon Lawn" />
        </SwiperSlide>
        <SwiperSlide className="swiper">
          <img src={img8} alt="Fountain with light" />
        </SwiperSlide>
        <SwiperSlide className="swiper">
          <img src={img10} alt="Girrafe Statue at Ten Spoon" />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Gallery;
