import { Heading } from '@chakra-ui/react';
import React from 'react';
import '../styles/Rooms.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import room1 from '../images/room1.jpg';
// import room2 from '../images/room2.jpg';
// import room3 from '../images/room3.jpg';
import room4 from '../images/room4.jpg';
import room5 from '../images/room5.jpg';
import room6 from '../images/room6.jpg';
// import room7 from '../images/room7.jpg';

const Rooms = () => {
  return (
    <div className="room-container" id="rooms">
      <Heading
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingTop={'20'}
      >
        Room
      </Heading>
      <div className="about__subheading">
        <p>Book your</p>
        <span>Room</span>
      </div>
      <div className="room__container">
        <div className="room__images">
          <Swiper
            slidesPerView={1}
            modules={[Navigation, Pagination]}
            navigation={{
              clickable: true,
            }}
            pagination={{
              clickable: true,
            }}
            className="roomSwipper"
          >
            <SwiperSlide>
              <img src={room1} alt="King Size bed" />
            </SwiperSlide>
            {/* <SwiperSlide>
              <img src={room2} alt="Telivision in Ten Spoon Dhaba" />
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <img
                src={room3}
                alt="@ beds with telivision and air conditioner"
              />
            </SwiperSlide> */}
            <SwiperSlide>
              <img src={room5} alt="Ten Spoon Dhaba room with a table and 2 beds with air conditioner" />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={room6}
                alt="Long gallery at Ten Spoon Dhaba"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src={room4} alt="Bed with Almirah" />
            </SwiperSlide>
            {/* <SwiperSlide>
              <img src={room7} alt="2 beds with a table with four chairs" />
            </SwiperSlide> */}
          </Swiper>
        </div>
        <div className="content">
          We provide best rooms with luxurious facilities that gives you best service
          experience at minimum cost. <br />
          There is best garden which gives you freshness and legacy.
          <br />
          <br />
          <br />
          <h4>Don't forget to visit</h4>
          <h3>Thank You !</h3>
        </div>
      </div>
    </div>
  );
};

export default Rooms;
