import { Container, Heading } from '@chakra-ui/react';
import React, { useState } from 'react';
import buttermasaladosa from '../images/butter masala dosa.png';
// import pannertikka from '../images/paneer tikka.png';
// import gravychaap from '../images/gravy chaap.png';
import dahibhalla from '../images/dahi bhalla.png';
import redsaucepasta from '../images/red sauce pasta.png';
import papayashake from '../images/papaya shake.png';
// import sandwichandcolddrink from '../images/sandwich with cold coffee.png';
// import shahipaneerandstuffednaan from '../images/shahi paneer with stuffed naan.png';
import pakoraandtea from '../images/pakora with tea.png';
// import parathaandcurd from '../images/paratha with curd.png';
import rasmalai from '../images/ras malai.png';
import chai from '../images/chai.png';
import panner from '../images/chilli paneer.png';
import potato from '../images/chilli potato.png';
import coffee from '../images/coffee.png';
// import burgerCombo from '../images/burger combo.png';
// import dalmakhni from '../images/dal makhani ad stuffed naan.png';
import lassi from '../images/lassi.png';
import manchurian from '../images/manchurian.png';
// import milk from '../images/milk.png';
import noodles from '../images/noodles.png';
// import pizzaand from '../images/pizza and cold drink.png';
import softdrink from '../images/soft drink.png';
import specialThali from '../images/special thali.png';
import springroll from '../images/spring roll.png';
import vanilla from '../images/vanilla shake.png';
import whitesaucepasta from '../images/white sauce pasta.png';
import '../styles/Menu.css';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const Menu = () => {
  // const [activeCombo, setActiveCombo] = useState(true);
  const [activeThali, setActiveThali] = useState(true);
  const [activeBeverage, setActiveBeverage] = useState(false);
  const [activeChinese, setActiveChinese] = useState(false);

  // const activateCombo = () => {
  //   setActiveCombo(true);
  //   setActiveThali(false);
  //   setActiveBeverage(false);
  //   setActiveChinese(false);
  // };

  const activateThali = () => {
    // setActiveCombo(false);
    setActiveThali(true);
    setActiveBeverage(false);
    setActiveChinese(false);
  };

  const activateBeverage = () => {
    // setActiveCombo(false);
    setActiveThali(false);
    setActiveBeverage(true);
    setActiveChinese(false);
  };

  const activateChinese = () => {
    // setActiveCombo(false);
    setActiveThali(false);
    setActiveBeverage(false);
    setActiveChinese(true);
  };

  return (
    <Container id="menu" className="menu">
      <Heading display={'flex'} justifyContent={'center'}>
        Our Menu
      </Heading>
      <div className="menu__subheading">
        <p>Check Our</p>
        <span>Delicous Menu</span>
      </div>
      <ul className="menu__nav">
        {/* <li>
          <HashLink to="#menu-combo" onClick={activateCombo}>
            <h4 className={activeCombo ? 'active' : ''}>Combo</h4>
          </HashLink>
        </li> */}
        <li>
          <HashLink
            to="#menu-thali"
            onClick={activateThali}
            className="activeThali"
          >
            <h4 className={activeThali ? 'active' : ''}>Special</h4>
          </HashLink>
        </li>
        <li>
          <HashLink
            to="#menu-beverages"
            onClick={activateBeverage}
            className="activeBeverage"
          >
            <h4 className={activeBeverage ? 'active' : ''}>Beverages</h4>
          </HashLink>
        </li>
        <li>
          <HashLink
            to="#menu-chinese"
            onClick={activateChinese}
            className="activeChinese"
          >
            <h4 className={activeChinese ? 'active' : ''}>Chinese</h4>
          </HashLink>
        </li>
      </ul>
      {/* <div id="menu-combo" className={activeCombo ? 'show' : 'hide'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p id="italics">Menu</p>
          <h1 style={{ color: 'rgb(148, 82, 82)' }}>Combos</h1>
        </div>
        <div className="menu__cards">
          <div className="menu__card">
            <img src={dalmakhni} alt="" />
            <h4>Dal Makhni and Stuffed Naan</h4>
          </div>
          <div className="menu__card">
            <img src={pizzaand} alt="" />
            <h4>Cheese Pizza with Cold Drink</h4>
          </div>
          <div className="menu__card">
            <img src={parathaandcurd} alt="" />
            <h4>Paratha and Curd</h4>
          </div>
          <div className="menu__card">
            <img src={sandwichandcolddrink} alt="" />
            <h4>SandWich and Cold Drink</h4>
          </div>
          <div className="menu__card">
            <img src={burgerCombo} alt="" />
            <h4>Burger Combo</h4>
          </div>
          <div className="menu__card">
            <img src={shahipaneerandstuffednaan} alt="" />
            <h4>Shahi Panner With Stuffed Naan</h4>
          </div>
        </div>
      </div> */}
      <div id="menu-thali" className={activeThali ? 'show' : 'hide'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>MENU</p>
          <h1 style={{ color: 'rgb(148, 82, 82)' }}>Special</h1>
        </div>
        <div className="menu__cards">
          <div className="menu__card">
            <img src={specialThali} alt="Special Thali at Ten Spoon Dhaba" />
            <h4>Special Thali</h4>
          </div>
          <div className="menu__card">
            <img src={panner} alt="Panner Tikka" />
            <h4>Panner</h4>
          </div>
          <div className="menu__card">
            <img src={buttermasaladosa} alt="Butter masala dosa" />
            <h4>Butter Masala Dosa</h4>
          </div>
          <div className="menu__card">
            <img src={dahibhalla} alt="Dahi bhalla" />
            <h4>Dahi Bhalla</h4>
          </div>
          <div className="menu__card">
            <img src={rasmalai} alt="Ras malai" />
            <h4>Ras Malai</h4>
          </div>
          <div className="menu__card">
            <img src={pakoraandtea} alt="Pakoda and Tea" />
            <h4>Pakoda and Tea</h4>
          </div>
        </div>
      </div>
      <div id="menu-beverages" className={activeBeverage ? 'show' : 'hide'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>MENU</p>
          <h1 style={{ color: 'rgb(148, 82, 82)' }}>Beverages</h1>
        </div>
        <div className="menu__cards">
          <div className="menu__card">
            <img src={vanilla} alt="Vanilla Shake" />
            <h4>Vanilla Shake</h4>
          </div>
          <div className="menu__card">
            <img src={chai} alt="Tea Chai" />
            <h4>Chai</h4>
          </div>
          <div className="menu__card">
            <img src={coffee} alt="Hot Coffee" />
            <h4>Hot Coffee</h4>
          </div>
          <div className="menu__card">
            <img src={papayashake} alt="Papaya Shake" />
            <h4>Papaya Shake</h4>
          </div>
          <div className="menu__card">
            <img src={softdrink} alt="Soft Drink" />
            <h4>Soft Drink</h4>
          </div>
          <div className="menu__card">
            <img src={lassi} alt="Lassi" />
            <h4>Lassi</h4>
          </div>
        </div>
      </div>
      <div id="menu-chinese" className={activeChinese ? 'show' : 'hide'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>MENU</p>
          <h1 style={{ color: 'rgb(148, 82, 82)' }}>Chinese</h1>
        </div>
        <div className="menu__cards">
          <div className="menu__card">
            <img src={springroll} alt="Spring Roll" />
            <h4>Spring Roll</h4>
          </div>
          <div className="menu__card">
            <img src={potato} alt="Chilli Potato" />
            <h4>Chilli Potato</h4>
          </div>
          <div className="menu__card">
            <img src={noodles} alt="Masala Noodles" />
            <h4>Noodles</h4>
          </div>
          <div className="menu__card">
            <img src={manchurian} alt="Manchurian" />
            <h4>Manchurian</h4>
          </div>
          <div className="menu__card">
            <img src={redsaucepasta} alt="Red Sauce Pasta" />
            <h4>Red Sauce Pasta</h4>
          </div>
          <div className="menu__card">
            <img src={whitesaucepasta} alt="White Sauce Pasta" />
            <h4>White Sauce Pasta</h4>
          </div>
        </div>
      </div>
      <span className="more">
        <Link to="/menudetails">Check Out for more</Link>
      </span>
    </Container>
  );
};

export default Menu;
