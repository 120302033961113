import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import '../styles/Header.css';
import logo from '../images/logo.jpg';
import { RiApps2Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const Header = () => {
  const [toggle, setToggle] = useState(false);

  // handle menu click
  const handleDrawer = () => {
    setToggle(!toggle);
  };
  return (
    <header>
      <div className="navbar">
        <div className="logo">
          <HashLink to={'/'}>
            <img src={logo} alt=" Ten Spoon Dhaba logo" />
            Ten Spoon
          </HashLink>
        </div>
        <ul className="links">
          <li>
            <HashLink to={'/'}>Home</HashLink>
          </li>
          <li>
            <HashLink to={'/#about'}>About</HashLink>
          </li>
          <li>
            <HashLink to={'/#menu'}>Menu</HashLink>
          </li>
          <li>
            <HashLink to={'/#rooms'}>Room</HashLink>
          </li>
          <li>
            <HashLink to={'/#moments'}>Moments</HashLink>
          </li>
          <li>
            <HashLink to={'/#gallery'}>Gallery</HashLink>
          </li>
          <li>
            <HashLink to={'/#contact'}>Contact</HashLink>
          </li>
        </ul>
        <Link to="/booktable" className="action_btn">
          Book your table/room
        </Link>
        <div className="toggle_btn" onClick={handleDrawer}>
          <RiApps2Line />
        </div>
      </div>
      <div className={toggle ? 'dropdown_menu open' : 'dropdown_menu'}>
        <li>
          <HashLink to={'/'}>Home</HashLink>
        </li>
        <li>
          <HashLink to={'/#about'}>About</HashLink>
        </li>
        <li>
          <HashLink to={'/#menu'}>Menu</HashLink>
        </li>
        <li>
          <HashLink to={'/#rooms'}>Room</HashLink>
        </li>
        <li>
          <HashLink to={'/#moments'}>Moments</HashLink>
        </li>
        <li>
          <HashLink to={'/#gallery'}>Gallery</HashLink>
        </li>
        <li>
          <HashLink to={'/#contact'}>Contact</HashLink>
        </li>
        <li>
          <Link to="/booktable" className="action_btn">
            Book your table/room
          </Link>
        </li>
      </div>
    </header>
  );
};

export default Header;
