import React from 'react';
import About from './About';
import Menu from './Menu';
import Moments from './Moments';
import Gallery from './Gallery';
import Contact from './Contact';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img1 from '../images/intro.jpg';
import img2 from '../images/table.jpg';
import img3 from '../images/franchise.jpg';
import '../styles/Home.css';
import { ToastContainer } from 'react-toastify';
import ScrollUp from './ScrollUp';
import Rooms from './Rooms';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Ten Spoon Dhaba is the best restaurant in Smalkha, Panipat, Haryana. We hvae variety of dishes at very affordable price."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="home" id="home">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          className={'carousel'}
          showArrows={true}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
        >
          <div>
            <img
              src={img1}
              alt="Experience the real and unique taste of mouthwatering food"
            />
          </div>
          <div>
            <img src={img3} alt="We are open for franchise" />
          </div>
          <div>
            <img
              src={img2}
              alt=" Come and enjoy delicious food at Ten Spoon Dhaba"
            />
          </div>
        </Carousel>
        <About />
        <Menu />
        <Rooms />
        <Moments />
        <Gallery />
        <Contact />
        <ScrollUp />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default Home;
