import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <Container paddingTop={'45'} className="contact__container" id="contact">
      <Heading
        margin={'10'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        Contact
      </Heading>
      <div className="contact__subheading">
        <p>Need Help?</p>
        <span>Contact Us</span>
      </div>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3482.2139294694753!2d77.0165414761794!3d29.217261357286592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dc90f9e5ef247%3A0x6e52ed14029fbe88!2sTen%20Spoon%20Dhaba!5e0!3m2!1sen!2sin!4v1685170245845!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{
          border: 0,
        }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="contact__details">
        <div className="contact__card">
          <h3>Our Address</h3>
          <h4>
            NH 44 Patikliyana, NH 1, Pattikalyana - Dikadla Rd, Samalkha,
            Haryana 132101
          </h4>
        </div>
        <div className="contact__card">
          <h3>Email Us</h3>
          <h4>dhabatenspoon @gmail.com</h4>
        </div>
        <div className="contact__card">
          <h3>Call Us</h3>
          <h4>+91 87599 46000</h4>
        </div>
        <div className="contact__card">
          <h3>Opening Hours</h3>
          <h4>24 x 7</h4>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
