import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineWhatsApp,
} from 'react-icons/ai';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <Container
      className="footer__container"
      paddingTop={'25'}
      bgColor={'#1d1d52'}
      color={'white'}
    >
      <div className="grid">
        <div className="grid__card">
          <h2>Address</h2>
          <p id="address">
            NH 44 Patikliyana, NH 1, Pattikalyana - Dikadla Rd, Samalkha,
            Haryana 132101
          </p>
        </div>
        <div className="grid__card">
          <h2>Reservations</h2>
          <p>Phone: +91 87599 46000</p>
          <p>Email: dhabatenspoon @gmail.com</p>
        </div>
        <div className="grid__card">
          <h2>Opening Hours</h2>
          <p>24 x 7</p>
        </div>
        <div className="grid__card">
          <h2>Follow Us</h2>
          <div className="icons">
            <a
              href="https://www.facebook.com/tenspoon.dhaba.7?mibextid=ZbWKwL"
              target="blank"
            >
              <AiOutlineFacebook />
            </a>
            <a href="https://www.instagram.com/" target="blank">
              <AiOutlineInstagram />
            </a>
            <a href="https://wa.me/+918759946000" target="blank">
              <AiOutlineWhatsApp />
            </a>
          </div>
        </div>
      </div>
      <Heading
        paddingBottom={'20'}
        display={'flex'}
        justifyContent={'center'}
        className="copyright"
      >
        © Copyright Ten Spoon. All Rights Reserved
      </Heading>
      <Heading
        paddingBottom={'20'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className="design"
      >
        Designed by{' '}
        <a
          href="https://www.instagram.com/gronaa.in/"
          target="blank"
          style={{
            fontFamily: 'Dancing Script',
            fontSize: '2.5rem',
            fontWeight: '700',
            paddingLeft: '10px',
            color: '#bf403d',
          }}
        >
          Gronaa
        </a>
      </Heading>
    </Container>
  );
};

export default Footer;
